<template>
  <!-- 页面名称：登录
  开发人员：zqq-->
  <div class="login-wrap">
    <div class="login-bg"></div>
    <div class="login-logo">
      <img src="../../assets/img/logo.png" alt="logo" />
    </div>
    <div class="login-box">
      <h1 class="login-title">密码登录</h1>
      <!-- 账号 -->
      <div class="phone input-wrap">
        <p class="account-number">输入账号</p>
        <input class="right-input" v-model="phone" type="text" placeholder="请输入您的登录账号" />
      </div>
      <!-- 密码 -->
      <div class="password input-wrap">
        <p class="account-password">输入密码</p>
        <input v-model="password" type="password" placeholder="请输入密码" />
      </div>
      <!-- 登录按钮 -->
      <div class="login-button">
        <el-button class="button" v-on:click="login()" :loading="loading">
          {{
          loginText
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { postLogin } from "../../http/api/login";

export default {
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {
    this.init();
  },

  data() {
    //数据
    return {
      phone: "",
      password: "",
      loading: false,
      loginText: "登录"
    };
  },
  methods: {
    init() {
      var my_token = localStorage.getItem("my_token");
      var valid_end_time = localStorage.getItem("valid_end_time");

      if (valid_end_time != null && valid_end_time != "") {
        var deTime = new Date(
          valid_end_time.substring(0, 19).replace(/-/g, "/")
        ).getTime();
        var newTime = new Date().getTime();

        if (newTime > deTime) {
          //过期
        } else {
          //有效
          if (my_token != "" || my_token != null) {
            //my_token 存在
            this.$router.push("/home/course");
          } else {
            //my_token 不存在
          }
        }
      }
    },
    //登录
    login() {
      this.loading = true;
      this.loginText = "登录中";
      if (this.phone === "") {
        this.loading = false;
        this.loginText = "登录";
        return this.$message({
          showClose: true,
          duration: 1000,
          message: "账号不能为空",
          type: "error"
        });
      } else if (this.password === "") {
        this.loading = false;
        this.loginText = "登录";
        return this.$message({
          showClose: true,
          duration: 1000,
          message: "密码不能为空",
          type: "error"
        });
      } else {
        postLogin({
          password: this.password,
          phone: this.phone
        })
          .then(res => {
            this.loading = false;
            this.loginText = "登录";
            if (res.code == 200) {
              this.$message.success("登录成功");
              localStorage.setItem("my_token", res.data.my_token);
              localStorage.setItem("valid_end_time", res.data.valid_end_time);
              this.$router.push("/home/course");
            }
          })
          .catch(() => {
            this.loading = false;
            this.loginText = "登录";
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/login.png") no-repeat;
  background-size: contain;
  .login-logo {
    width: 155px;
    height: 47px;
    position: absolute;
    top: 31px;
    left: 4.8%;

    img {
      width: 100%;
    }
  }
  .login-box {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 603px;
    height: 477px;
    background: #ffffff;
    border-radius: 6px;
    z-index: 10;
    .login-title {
      text-align: center;
      width: 100%;
      margin: 38px auto;
      font-size: 26px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #00264d;
    }
    .input-wrap {
      margin: 17px 96px;
    }
    .account-number {
      margin-top: 60px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #00264d;
      margin-bottom: 8px;
      // margin-left: 96px;
    }
    .account-password {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #00264d;
      margin-bottom: 8px;
      margin-top: 1px;
      // margin-left: 96px;
    }
    input {
      width: 413px;
      height: 54px;
      box-sizing: border-box;
      border-radius: 7px;
      padding-left: 16px;
      font-size: 15px;
      border: 1px solid #cbdafe;
      // border: none;
      color: #00264d;
    }
    input:focus {
      outline: none;
      border: 1px solid #0d80ff;
      border-radius: 7px;
      border-color: #1977e3;
    }

    input:hover {
      border-color: #1977e3;
    }
    .login-button {
      margin-top: 40px;
      margin-left: 94px;
      .button {
        width: 413px;
        line-height: 54px;
        border: 0;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        background: #0d80ff;
        border-radius: 6px;
      }
    }
  }
  ::v-deep .el-button {
    padding: 0;
  }
}
/* 最小 */
@media screen and (max-width: 1024px) {
  .login-wrap{
    .login-bg{
      width: 100%;
      height: 50%;
      background: url("../../assets/img/login.png") no-repeat;
      background-size: cover;
    }
    .login-box {
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 303px;
      height: 360px;
      background: #ffffff;
      border-radius: 6px;
      z-index: 10;
      .login-title {
        margin: 0;
        margin-top: 10px;
        text-align: center;
        width: 100%;
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #00264d;
      }
      .input-wrap {
        margin: 15px 40px;
      }
      .account-number {
        margin: 0;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #00264d;
        // margin-left: 96px;
      }
      .account-password {
        margin: 0;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #00264d;
      }
      input {
        width: 220px;
        height: 36px;
        border-radius: 7px;
        padding-left: 16px;
        font-size: 15px;
        border: 1px solid #cbdafe;
        color: #00264d;
      }
      input:focus {
        outline: none;
        border: 1px solid #0d80ff;
        border-radius: 7px;
        border-color: #1977e3;
      }

      input:hover {
        border-color: #1977e3;
      }
      .login-button {
        margin: 0;
        margin: 40px 40px 0 40px;
        .button {
          width: 220px;
          line-height: 54px;
          border: 0;
          text-align: center;
          cursor: pointer;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
          background: #0d80ff;
          border-radius: 6px;
        }
      }
    }
  }

}
</style>